
import './index.scss';
import { Plus } from 'react-bootstrap-icons';

const CreateTodoButton = ({setCreateTodoModalOpen}) =>{


    const onClickButton = () =>{

        setCreateTodoModalOpen(prevState => !prevState);
    }

    return(
        <>
        <div className='btn-container'>
            <button 
                className='CreateTodoButton'
                onClick={onClickButton}
            >
                <Plus/>
            </button>
        </div>
        </>
    );


}


export {CreateTodoButton}