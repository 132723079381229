import React,{useState,useContext, useEffect} from "react";
import { TodoContext } from "../../contexts/todoContext";
import { Row, Col } from "reactstrap";
import { Check,X,ListCheck } from "react-bootstrap-icons";


import "./index.scss";

export const CreateTodo = ({setIsNewTodoCreated}) =>{

    const {setCreateTodoModalOpen,createTodo} = useContext(TodoContext)
    
    const initial_state_new_todo = {text:"",description:""}
    
    const [newTodo,setNewTodo] = useState(initial_state_new_todo);

    const [enableButton,setEnableButton] = useState(false);
    

    const closeModal = () =>{

        setCreateTodoModalOpen(prevState => !prevState);
    
    }

    

    const handleInput = (event) =>{
        let handle_name=event.target.name;
        let handle_value=event.target.value;
        //console.log(handle_value);
        setNewTodo({...newTodo,[handle_name]:handle_value})
    }

    useEffect(()=>{
    
        const checkValues = ( ) => {
        
            let text = newTodo.text;
            let description = newTodo.description;
            
            
            if (text !== "" && text !== undefined){
                setEnableButton(true);
            }else{
                setEnableButton(false);
            }
            if (description !== "" && text !== ""){
                setEnableButton(true);
               
            }
        };

        checkValues();
    
    },
        [newTodo]
    );

    const onCreateTodo =  () =>{
        
        let result=createTodo(newTodo.text,newTodo.description);

        if (result){
            setNewTodo(initial_state_new_todo);
            setCreateTodoModalOpen(false);
            setIsNewTodoCreated(true);
        }
    }

    
    
    return (
        
            <div className="CreateTodoModal">
                
                 <Row className="d-flex justify-content-center">
                       
                    <Col xs={12} md={4} className="TodoModal p-0 m-0">
                        <Row  md={12} xs={12} className="TodoModalHeader m-0 p-0" >
                            <Col md={12} xs={12} className="TodoModalHeaderBar d-flex"></Col>
                                
                            <Col  md={12} className="d-flex flex-row justify-content-center align-items-center">
                                <div className="TodoModalHeaderLogo d-flex justify-content-center align-items-center">
                                    <ListCheck/>
                                </div>
                            </Col> 
                            <Col md={12} className="TodoModalHeaderTitle d-flex justify-content-center m-1">
                                <p>New Task</p>
                            </Col>
                        </Row>
                        <Row className="WrapModalElements d-flex justify-content-center">
                            <Row md={12} xs={12} className="InputsContainer d-flex justify-content-center p-0 m-0"> 
                                <Col md={12} xs={12} className="d-flex justify-content-center">
                                    <input 
                                        className="TodoTitleInput"
                                        placeholder="Insert your ToDo title"
                                        name="text"
                                        autocomplete="off"
                                        onChange={(e)=>{
                                            handleInput(e);
                                        }}
                                        value={newTodo.text}
                                        
                                    >
                                    </input>
                                </Col>
                                <Col md={12} xs={12} className="d-flex justify-content-center">
                                    <textarea
                                        className="TodoDescriptionInput"
                                        placeholder="Describe your Todo"
                                        name="description"
                                        autocomplete="off"
                                        onChange={(e)=>{
                                            handleInput(e);   
                                        }}
                                        value={newTodo.description}
                                    >
                                       
                                    </textarea>
                                </Col>
                            </Row>
                            <Col md={12} xs={12} className="ButtonsContainer d-flex justify-content-between">
                                <button onClick={closeModal} className="Button">
                                    <X/>
                                </button>
                                <button onClick={onCreateTodo} className="Button Accept" disabled={!enableButton} >
                                    <Check/>
                                </button>
                            </Col>
                        </Row>
                    </Col>
                 </Row>
                 
            </div>  
    )


}


