import { useContext } from 'react';
import { TodoContext } from '../../contexts/todoContext';
import './index.scss';

const TodoCounter = () => {

    const {taskStatus} = useContext(TodoContext);

    let tasks=taskStatus;
    
    return(
        <>
            <div className='TodoCounter'>
                <h3 className='CounterTitle'>Completed</h3>
                <h2 className='Counter'>{tasks.completed} / {tasks.total}</h2>
            </div>
        </>
    );

}



//aca se pueden hacer 2 tipos de export

// el default se usa cuando solo tenemos una unica funcion en nuestro componente, cuando es mas de unica se usa con {}


/* 1. */ // export default TodoCounter; de esta manera exportamos todo y para utilizarlo en otro componente lo llamamo   --- > import TodoCounter from './components/TodoCounter' , le podemos poner el nombre que queramos al import

/* 2. */export {TodoCounter}  //si o si cuando lo llamamos se llama de la siguiente manera import {TodoCounter} from './components/TodoCounter'