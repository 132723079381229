import { useContext,useState } from "react";
import { TodoContext } from "../../../contexts/todoContext";
import { Row, Col } from "reactstrap";
import { Check,X,PencilFill,EyeFill} from "react-bootstrap-icons";
import './index.scss';

export const ShowTodo = ({toast}) =>{



    const {setShowTodoModalOpen,setSelectedTodo,selectedTodo, updateTodoInformation} = useContext(TodoContext)
  
    
    const [updatedTodo,setUpdatedTodo] = useState(selectedTodo); 

    const [editTodo,setEditTodo] = useState(false)

    const closeModal = () =>{

        setShowTodoModalOpen(prevState => !prevState);

    }

    const handleInput = (event) =>{
        let handle_name=event.target.name;
        let handle_value=event.target.value;
        setUpdatedTodo({...updatedTodo,[handle_name]:handle_value});
        
    }

    const updateTodo = () =>{
        
        let result = updateTodoInformation(selectedTodo.id,updatedTodo.text,updatedTodo.description);

        if (result){

            setSelectedTodo({...selectedTodo,text:updatedTodo.text,description:updatedTodo.description});
            
            setEditTodo(prevState => !prevState);

            toast.success(
                "Task has been updated",
                {
                    position:"top-center",
                    style:{
                        background:"#fff",
                        width:"100%"
                    }
                }
            );

            // toast.custom(
                // <Toast 
                    // image={"https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=6GHAjsWpt9&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"}
                    // name={"mariano"} 
                    // text={"hola mundo"}
                   
                // />
            // )
        }

    }

    const cancelEdition = ( ) =>{
        setEditTodo(prevState=>!prevState);
        setUpdatedTodo({...selectedTodo});
    }
    

    return(

        <div className="SeeTodoModal">
          
             
            <Row className="d-flex justify-content-center">   

                <Col xs={12} md={4} className="TodoModal p-0 m-0">
                    <Row  md={12} xs={12} className="TodoModalHeader m-0 p-0" >
                        <Col md={12} xs={12} className="TodoModalHeaderBar d-flex"></Col>
                            
                        <Col  md={12} className="d-flex flex-row justify-content-center align-items-center">
                            <div className="TodoModalHeaderLogo d-flex justify-content-center align-items-center">
                                {editTodo && <PencilFill/>}
                                {!editTodo && <EyeFill/>}
                            </div>
                        </Col> 
                        <Col md={12} className="TodoModalHeaderTitle d-flex justify-content-center m-1">
                            {editTodo && <p>Edit Task</p>}
                            {!editTodo && <p>View Task</p>}
                        </Col>
                    </Row>
                    <Row className="WrapModalElements d-flex justify-content-center">
                        <Row md={12} xs={12} className="InputsContainer d-flex justify-content-center p-0 m-0"> 
                            <Col md={12} xs={12} className="d-flex justify-content-center">
                                <input 
                                    className="TodoTitleInput"
                                    placeholder="Insert your ToDo title"
                                    name="text"
                                    autocomplete="off"
                                    onChange={handleInput}
                                    value={updatedTodo.text}
                                    disabled={!editTodo ? "disabled" : ""}
                                    
                                >
                                </input>
                            </Col>
                            <Col md={12} xs={12} className="d-flex justify-content-center">
                                <textarea
                                    className="TodoDescriptionInput"
                                    placeholder="Describe your Todo"
                                    name="description"
                                    autocomplete="off"
                                    onChange={handleInput}
                                    value={updatedTodo.description}
                                    disabled={!editTodo ? "disabled" : ""}
                                >
                                    
                                </textarea>
                            </Col>
                        </Row>
                        <Col md={12} xs={12} className="ButtonsContainer d-flex justify-content-between">


                            { editTodo && 
                                <>
                                    <button onClick={cancelEdition} className="Button">
                                        <X className="CancelEdit"/>                                 
                                    </button>
                                    
                                    <button 
                                        onClick={updateTodo} 
                                        className="Button Accept" 
                                        disabled={
                                            (selectedTodo.text !== updatedTodo.text) || (selectedTodo.description !== updatedTodo.description) ?  "" : true
                                        }
                                    >                                
                                        <Check className="AcceptEdition"/>  
                                    </button>
                                </>
                            }
                            
                            {
                                !editTodo && 
                                    <>

                                        <button onClick={closeModal} className="Button">
                                            <X className="CancelEdit"/>  
                                        </button>
                                        
                                        <button onClick={()=>{setEditTodo(prevState => !prevState)}} className="Button">
                                            <PencilFill className="EditTodo"/>                                 
                                        </button>
                                    </>

                            }
                        </Col>
                    </Row>
                </Col>
            </Row>    
        </div>  
    )

}