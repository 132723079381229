
import { Row,Col } from 'reactstrap';
import { Trash3Fill,CheckSquare,Square,EyeFill} from 'react-bootstrap-icons'

import "./index.scss"



const TodoItem = ({todo,deleteTodo,updateTodo,showTodo}) => {
 
   
    let iscompleted = todo.completed ? "TodoCompleted" : "TodoNotCompleted"

    return(

        <Row className={`TodoItemContainer p-0 d-flex justify-content-center align-items-center mt-2 mb-2`} id={todo.id}>
            <Col xs="11" md="11" className={`TodoItem  ${iscompleted} d-flex justify-content-center align-items-center`}>
                <Col xs="2" className='d-flex justify-content-center align-items-center '>

                    {
                        todo.completed ? 
                        <CheckSquare 
                            onClick={()=>(updateTodo(todo.id))}
                            className='TodoCheck'
                        />
                        : 
                        <Square 
                            onClick={()=>(updateTodo(todo.id))}
                            className='TodoCheck'
                        />
                        
                    }  
                </Col>
                <Col xs="8" className='d-flex justify-content-center align-items-center'>
                    <span
                        className={todo.completed ? "Completed" : null}
                    >
                        {todo.text}
                    </span>
                </Col>  
                <Col xs="2" className='d-flex justify-content-center align-items-center'>
                    <EyeFill 
                        onClick={()=>{showTodo(todo.id)}}
                        className='ShowTodo'
                    />
                    <Trash3Fill 
                        onClick={()=>{deleteTodo(todo.id)}}
                        className='TodoRemove'
                    />
                </Col>
            </Col>
        </Row>

    );



};



export {TodoItem};