
import {createPortal} from 'react-dom';
import { useContext } from 'react';
import { TodoContext } from '../../../contexts/todoContext';
import './index.scss'

export const Modal= ({children}) =>{

    // createPortal(#,$)
    const {setModalOpen} = useContext(TodoContext)

    return createPortal(
        <>
            <div className='BackdropModal' onClick={()=>setModalOpen(prevState => !prevState)}></div> 
            {children}
        </>
        ,
        document.getElementById('modal')
    )


}


