import { useContext } from 'react';
import { TodoContext } from '../../contexts/todoContext';

import { Row,Col } from 'reactstrap';

import { X } from 'react-bootstrap-icons'

import "./index.scss"


 
const TodoSearcher = () =>{
    
    const {searchValue,setSearchValue} = useContext(TodoContext);
    
    const onSearchValueChange = (event) =>{
        setSearchValue(event.target.value)
    }

    const deleteSearchValue = () =>{
        setSearchValue("")
    }

    return(

        <Row className='d-flex justify-content-center align-items-center p-0 m-0'>
            <Col xs="11" md="4" className='d-flex justify-content-center align-items-center p-0 m-0'>
                <div className="TodoSearcher">


                    <input 
                        
                        placeholder='Cebolla'
                        onChange={onSearchValueChange}  
                        value={searchValue}  
                    />

                    {
                        searchValue !== "" 
                        ?
                            <X className="clearInput" onClick={deleteSearchValue}/>
                        :
                            ""
                    }

                </div>
            </Col>
            
                

        </Row>
        
    )

}


export {TodoSearcher}