import { useContext, useState } from 'react';
import { TodoContext } from './contexts/todoContext';
import { Toaster ,toast} from 'react-hot-toast'; 
import { Container,} from 'reactstrap';
import { TodoCounter } from './components/TodoCounter';
import { TodoSearcher } from './components/TodoSearcher';
import { TodoList } from './components/TodoList';
import { CreateTodo } from './components/CreateTodo';
import { Modal } from './components/modals/Modal';
import { ShowTodo } from './components/modals/ShowTodo';
import "./index.scss"


export const AppUI = () =>{

    const {createTodoModalOpen,showTodoModalOpen} = useContext(TodoContext)
    const [isNewTodoCreated,setIsNewTodoCreated ] = useState(false);

    return(
        <div className='app'>

            {!!isNewTodoCreated && 
                toast.success("Task has been created",setIsNewTodoCreated(prevState=>!prevState))
            } 
            <Toaster reverseOrder={false}/> 
            {!!createTodoModalOpen &&
                <Modal>
                    <CreateTodo toast={toast} setIsNewTodoCreated={setIsNewTodoCreated}/>
                </Modal>
            }
            {!!showTodoModalOpen &&
                <Modal>
                    <ShowTodo toast={toast}/>
                </Modal>
            }
            <TodoCounter   />
            
                
            
            <Container fluid className='ListContainer'>
                <TodoSearcher  />
                <TodoList />
            </Container>  
        </div>  
    )


}