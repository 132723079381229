import { useState,useEffect } from "react";



export const useLocalStorage = (itemName,initialValue) =>{

    const [item,setItems] = useState(initialValue);
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(false);

    useEffect(() => {
            setTimeout(()=>{

                try {
                    
                    let localStorageItem = JSON.parse(localStorage.getItem(itemName));
                    localStorageItem = !localStorageItem ? initialValue : localStorageItem 
    
                    setItems(localStorageItem);
                    setLoading(false);
                
                }  catch (e) {

                    console.error(e);
                    setError(true);
                    setLoading(false);
                
                }
            },1000);

        
    });

    const saveItem = (newItem) => {

        try {

            localStorage.setItem(itemName, JSON.stringify(newItem));
    
            setItems(newItem);

            return true;
            
        } catch (e) {

            console.error(e);
            setError(true);
            return false;
            
        }

    }
    
    return {
        item,
        saveItem,
        loading,
        error
    };

} 