import { TodoProvider } from './contexts/todoContext';

import { AppUI } from './AppUI';



const App = () => {

  

  return (
  <TodoProvider>
      <AppUI/>
  </TodoProvider>
  );
}

export default App;
