import { useContext} from 'react';
import { TodoContext } from '../../contexts/todoContext';
import {Row,Col} from 'reactstrap';
import Loader from "react-spinners/MoonLoader";
import { ExclamationTriangle,InboxFill,Search } from 'react-bootstrap-icons';

import { TodoItem } from '../TodoItem';
import { CreateTodoButton } from '../CreateTodoButton';


import "./index.scss"


const TodoList = () => {

    const {todos,todosFiltered,showTodo,deleteTodo,updateTodo,setCreateTodoModalOpen,loading,error} = useContext(TodoContext);

    


    return(

        <Row className="TodoListContainer d-flex-column align-items-center justify-content-center p-0 m-0 mt-3">
            
            <Row className='d-flex-column align-items-center justify-content-center p-0 m-0'>
                <Col xs="11" md="4" className="d-flex-column align-items-center justify-content-center TodoList">
                   
                    {loading && 
                        <Col xs={12} md={12} className="d-flex justify-content-center align-items-center TodoLoader" >

                            <Loader size={60} color={"#585858"} speedMultiplier={0.5}/>

                        </Col>
                    }
                        
                    {error && 
                       <Col xs={12} md={12} className="d-flex justify-content-center align-items-center TodoError" >

                            <ExclamationTriangle size={80}/>
                            <h2>
                                <span>We have an error</span>
                                <span>Please reload the site</span>
                            </h2>

                        </Col>
                    }

                    {!todos.length &&

                        <Col xs={12} md={12} className="d-flex justify-content-center align-items-center TodoEmpty" >

                            <InboxFill size={70}/>
                            <h2>Create a new Todo</h2>
                            
                        </Col>

                    }

                     {!todosFiltered.length && todos.length>0 &&

                        <Col xs={12} md={12} className="d-flex justify-content-center align-items-center TodoEmpty" >
                            <Search size={65}/>
                            <h2>Todo not find</h2>

                        </Col>

                    }


                        
                    {!loading && !error && todos.length > 0 && (

                            <Col className='TodoItems'>
                                { 
                                    todosFiltered?.map(
                                        (todo)=>{
                                            return <TodoItem todo={todo} key={todo.id} deleteTodo={deleteTodo} updateTodo={updateTodo} showTodo={showTodo}/>
                                        }
                                    )
                                }
                            </Col>
                        )
                    }   

                    <Col xs="11" md="4" className='TodoBar'></Col> 
            
                </Col>
                    
                <Col md="12" xs="12" className='d-flex justify-content-center align-items-center'>
                    <CreateTodoButton setCreateTodoModalOpen={setCreateTodoModalOpen} />
                </Col>
            </Row>
        </Row>

    )


}


export {TodoList}