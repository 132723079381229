import { createContext,useState} from "react";
import { useLocalStorage } from './../../hooks/useLocalStorage';
import {v4 as uuid} from 'uuid';

const TodoContext = createContext()


const TodoProvider = (props) =>{

    const {item:todos,saveItem:saveTodos,loading,error} = useLocalStorage('todos',[]);

    const [selectedTodo,setSelectedTodo] = useState({})

    const [searchValue,setSearchValue] = useState("");

    const [createTodoModalOpen,setCreateTodoModalOpen] = useState(false);

    const [showTodoModalOpen,setShowTodoModalOpen] = useState(false);

    const filterTodos = (array) => {
        
        let filteredTodos = array.filter((item) =>{
        
        let textSearch = searchValue.toLowerCase();
        let todoText = item.text.toLowerCase();

        return todoText.includes(textSearch);

        }) 

        return filteredTodos;
    };
    
    const createTodo = (text,description) =>{
        const uid = uuid()
        
        if (text.trim()!== "" && text !== undefined){
        
        const todo = {
            id:uid,
            text:text,
            description:description,
            completed: false
        }
        
        saveTodos([...todos,todo]);
            
        return true;
        }else{
        return false;
        };
    };
    
    const updateTodo = (id) =>{
        
        const newTodos = todos.map((todo)=>{
            
            if(todo.id===id){
            return {
                id:todo.id,
                text:todo.text,
                completed:todo.completed === true ? todo.completed = false : todo.completed = true
            }
            }else{
            return todo
        }
        
        }
        )
        
        saveTodos(newTodos);
        
    };

    const updateTodoInformation = (id,newText,newDescription) =>{

        const newTodos = todos.map((todo)=>{
            
            if(todo.id===id){

                return {
                    id:todo.id,
                    text:newText,
                    description:newDescription,
                    completed:todo.completed
            }
            }else{

                return todo
            }
        
        }
        );
        
        let todoSaved=saveTodos(newTodos);
        
        return todoSaved
    };
    
    const showTodo = (id) =>{
        let Todos = todos;
        
        let filterTodo = Todos.filter((todo) => todo.id === id);
        
        setSelectedTodo(filterTodo[0]);

        setShowTodoModalOpen(prevState => !prevState);
    };

    const deleteTodo = (id) =>{
    
        let newTodos = todos;
        
        newTodos = newTodos.filter((todo) => todo.id !== id);
        
        saveTodos(newTodos);

    };

    const taskStatus = () =>{
    
        const completed = todos.filter((element) => element.completed)
    
        return {

        total:todos.length,
        completed:completed.length

        }
    
    };
  
    let todosFiltered = searchValue === "" ? todos : filterTodos(todos);

    return(

        <TodoContext.Provider value={
            {

                loading,
                error,
                todosFiltered,
                todos,
                createTodo,
                updateTodo,
                showTodo,
                deleteTodo,
                selectedTodo,
                setSelectedTodo,
                updateTodoInformation,
                searchValue,
                setSearchValue,
                createTodoModalOpen,
                setCreateTodoModalOpen,
                showTodoModalOpen,
                setShowTodoModalOpen,
                taskStatus:taskStatus(todosFiltered),
                          

            }
        }>
            {props.children}
        </TodoContext.Provider>
    );
};


export {TodoContext,TodoProvider}  //contexto y provider del contexto